const loader = document.getElementById('loader');
//Making the loader dissapear when the page is loaded
window.addEventListener('load', () => {
    $('#loader').fadeOut('slow');
    setTimeout(() => {
        loader.style.display = 'none';
    }, 1000);
});

$(document).ready(()=>{
    //Background container fading to opacity 1 on load
    $('#background-container img').fadeTo(3000, 1);

    //Blue main menu container with pics fading in 
    setTimeout(() => {
        $('#second-container').addClass('animated fadeIn');
    }, 4500); //CHANGE BACK TO 4500

});

//Assigning the name of the girl playing 
function getName(name) {
    $(document).ready(function(){
        $('#first-container h1').slideUp('slow');
        $('#first-container').css('margin-top', "30px");
        $('#second-container').css('min-height', '90vh');
        $('.girl-name').text(name);
    });
}

document.getElementById("first-go-back-button").addEventListener("click", function () {
    $('#first-container h1').slideDown('slow');
    // $('#first-container').css('margin-top', 0);
    $('#second-container').css('min-height', '70vh');
});

// ------- WORDS GAME -------

let wordsPoints = 0;
let randKey;
let currentBulgWord;

//Displaying the current (0) for the words game points
document.getElementById('words-points').innerText = wordsPoints.toString();


//Getting the JSON data for the WORDS and assigning to variable
const words = (function () {
    let json = null;
    $.ajax({
        'async': false,
        'global': false,
        'url': 'js/words.json',
        'dataType': 'json',
        'success': function (data) {
            json = data;
        }
    });
    return json;
})();

//Getting the JSON data for the Hebrew Letters and assigning to variable
const hebrewLetters = (function () {
    let json = null;
    $.ajax({
        'async': false,
        'global': false,
        'url': 'js/hebrew-letters.json',
        'dataType': 'json',
        'success': function (data) {
            json = data;
        }
    });
    return json;
})();


//Picking a random property from WORDS
function pickRandomProperty(obj) {
    let result;
    let count = 0;
    for (let prop in obj)
        if (Math.random() < 1 / ++count)
            result = prop;
    return result;
}

//DISPlAYING key-value-pics in the appropriate places
function newWord() {
    randKey = pickRandomProperty(words);

    currentBulgWord = words[randKey][0];

    document.getElementById('bulg-word').innerHTML = currentBulgWord;
    
    document.getElementById('word-pic').setAttribute('src', words[randKey][1]);

    document.getElementById('player-submit-text').setAttribute('placeholder','_ '.repeat(randKey.length));
}

//Object with princesses
const princessObj = {
    ariel: 'Aриел',
    aurora: 'Аврора',
    belle: 'Бел',
    cinderella: 'Пепеляшка',
    elsa: 'Елза',
    jasmine: 'Жасмин',
    merida: 'Мерида',
    mulan: 'Мулан',
    pocahontas: 'Покахонтас',
    snowWhite: 'Снежанка',
    rapunzel: 'Рапунцел',
    tiana: 'Тиана'
};

const emojiObj = {
    smiler: "Смайлър",
    gene: "Джин",
    highFive: "Дай 5",
    jailbreak: "Терабайт",
    maryMeh: "Мери Ме",
    akiko: "Акико",
    melMeh: "Мел Ме"
};

const sofiaObj = {
    amber: "Ембър",
    clio: "Клио",
    clover: "Кловър",
    crackle: "Кракъл",
    hildegard:"Хилдегард",
    james: "Джеймс",
    jun: "Джун",
    leena: "Лина",
    lulu: "Лулу",
    mia: "Мия",
    minimus: "Минимус",
    miranda: "Миранда",
    robin: "Робин",
    roland: "Роланд",
    sofia: "София",
    vivian: "Вивиян",
    whatnaught: "Уатнот",
    zooey: "Зууи"
}

//Open pics MODAL
function picModal(character, type) {
    let picModalImage = document.getElementById('pics-modal-image');
    if(type == "princess") {
        picModalImage.setAttribute('src', 'img/princesses/' + character + '.jpg');
        picModalImage.setAttribute('alt', character);
        document.getElementsByClassName('character-name')[0].innerText = princessObj[character];
        if (character == "aviva"){
            document.getElementsByClassName('character-name')[0].innerText = "Авива и принцесите";
        } else if (character == "michaela"){
            document.getElementsByClassName('character-name')[0].innerText = "Михаела и принцесите";
        }
    } else if(type == "emoji") {
        picModalImage.setAttribute('src', 'img/emojis/' + character + '.jpg');
        picModalImage.setAttribute('alt', character);
        document.getElementsByClassName('character-name')[0].innerText = emojiObj[character];
    } else if (type == "sofia") {
        picModalImage.setAttribute('src', 'img/sofia/' + character + '.jpg');
        picModalImage.setAttribute('alt', character);
        document.getElementsByClassName('character-name')[0].innerText = sofiaObj[character];
    }

    document.getElementById('modal-button').click();
}

//Checking how many points the player has so far and opening the appropriate modal prize accordingly
function checkPoints(points, character){
    if(character === 'princess') {
        let princessesWon = document.getElementById('princesses-won');
        if (points === 5) {
            princessesWon.innerHTML += "<img src='img/princesses/snowWhite.jpg' alt='Snow White' class='img-fluid'>";
            picModal('snowWhite', 'princess');
        } else if (points === 10) {
            princessesWon.innerHTML += "<img src='img/princesses/aurora.jpg' alt='Aurora' class='img-fluid'>";
            picModal('aurora', 'princess');
        } else if (points === 15) {
            princessesWon.innerHTML += "<img src='img/princesses/merida.jpg' alt='Merida' class='img-fluid'>";
            picModal('merida', 'princess');
        } else if (points === 20) {
            princessesWon.innerHTML += "<img src='img/princesses/jasmine.jpg' alt='Jasmine' class='img-fluid'>";
            picModal('jasmine', 'princess');
        } else if (points === 25) {
            princessesWon.innerHTML += "<img src='img/princesses/belle.jpg' alt='Belle' class='img-fluid'>";
            picModal('belle', 'princess');
        } else if (points === 30) {
            princessesWon.innerHTML += "<img src='img/princesses/mulan.jpg' alt='Mulan' class='img-fluid'>";
            picModal('mulan', 'princess');
        } else if (points === 35) {
            princessesWon.innerHTML += "<img src='img/princesses/ariel.jpg' alt='Ariel' class='img-fluid'>";
            picModal('ariel', 'princess');
        } else if (points === 40) {
            princessesWon.innerHTML += "<img src='img/princesses/pocahontas.jpg' alt='Pocahontas' class='img-fluid'>";
            picModal('pocahontas', 'princess');
        } else if (points === 45) {
            princessesWon.innerHTML += "<img src='img/princesses/cinderella.jpg' alt='Cinderella' class='img-fluid'>";
            picModal('cinderella', 'princess');
        } else if (points === 50) {
            princessesWon.innerHTML += "<img src='img/princesses/tiana.jpg' alt='Tiana' class='img-fluid'>";
            picModal('tiana', 'princess');
        } else if (points === 55) {
            princessesWon.innerHTML += "<img src='img/princesses/elsa.jpg' alt='Elsa' class='img-fluid'>";
            picModal('elsa', 'princess');
        } else if (points === 60) {
            princessesWon.innerHTML += "<img src='img/princesses/rapunzel.jpg' alt='Rapunzel' class='img-fluid'>";
            picModal('rapunzel', 'princess');
        } else if (points === 75) {
            if(document.getElementsByClassName("girl-name")[0].innerText == "Авивче") {
                princessesWon.innerHTML += "<img src='img/princesses/aviva.jpg' alt='Aviva' class='img-fluid'>";
                picModal('aviva', 'princess');
            } else if (document.getElementsByClassName("girl-name")[0].innerText == "Михаелче") {
                princessesWon.innerHTML += "<img src='img/princesses/michaela.jpg' alt='Michaela' class='img-fluid'>";
                picModal('michaela', 'princess');
            } if (document.getElementsByClassName("girl-name")[0].innerText != "Авивче" && document.getElementsByClassName("girl-name")[0].innerText != "Михаелче") {
                princessesWon.innerHTML += "<img src='img/princesses/disney-princesses.jpg' alt='Aviva' class='img-fluid'>";
                picModal('disney-princesses', 'princess');
            }  
        }
    } else if (character === 'emoji') {
        let emojisWon = document.getElementById('emojis-won');
        if (points === 6) {
            emojisWon.innerHTML += "<img src='img/emojis/maryMeh.jpg' alt='Mery Meh' class='img-fluid'>";
            picModal('maryMeh', 'emoji');
        } else if (points === 12) {
            emojisWon.innerHTML += "<img src='img/emojis/highFive.jpg' alt='High Five' class='img-fluid'>";
            picModal('highFive', 'emoji');
        } else if (points === 18) {
            emojisWon.innerHTML += "<img src='img/emojis/jailbreak.jpg' alt='Jailbreak' class='img-fluid'>";
            picModal('jailbreak', 'emoji');
        } else if (points === 24) {
            emojisWon.innerHTML += "<img src='img/emojis/melMeh.jpg' alt='Mel Meh' class='img-fluid'>";
            picModal('melMeh', 'emoji');
        } else if (points === 30) {
            emojisWon.innerHTML += "<img src='img/emojis/smiler.jpg' alt='Smiler' class='img-fluid'>";
            picModal('smiler', 'emoji');
        } else if (points === 36) {
            emojisWon.innerHTML += "<img src='img/emojis/gene.jpg' alt='Gene' class='img-fluid'>";
            picModal('gene', 'emoji');
        } else if (points === 42) {
            emojisWon.innerHTML += "<img src='img/emojis/akiko.jpg' alt='Akiko' class='img-fluid'>";
            picModal('akiko', 'emoji');
        } 
    } else if (character === "sofia") {
        let sofiaCharactersWon = document.getElementById('sofia-characters-won');
        if (points === 4) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/clover.jpg' alt='Clover' class='img-fluid'>";
            picModal('clover', 'sofia');
        } else if (points === 8) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/hildegard.jpg' alt='Hildegard' class='img-fluid'>";
            picModal('hildegard', 'sofia');
        } else if (points === 12) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/jun.jpg' alt='Jun' class='img-fluid'>";
            picModal('jun', 'sofia');
        } else if (points === 16) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/miranda.jpg' alt='Miranda' class='img-fluid'>";
            picModal('miranda', 'sofia');
        } else if (points === 20) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/mia.jpg' alt='Mia' class='img-fluid'>";
            picModal('mia', 'sofia');
        } else if (points === 24) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/roland.jpg' alt='Roland' class='img-fluid'>";
            picModal('roland', 'sofia');
        } else if (points === 28) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/whatnaught.jpg' alt='Whatnaught' class='img-fluid'>";
            picModal('whatnaught', 'sofia');
        } else if (points === 32) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/amber.jpg' alt='Amber' class='img-fluid'>";
            picModal('amber', 'sofia');
        } else if (points === 36) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/crackle.jpg' alt='Crackle' class='img-fluid'>";
            picModal('crackle', 'sofia');
        } else if (points === 40) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/james.jpg' alt='James' class='img-fluid'>";
            picModal('james', 'sofia');
        } else if (points === 44) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/zooey.jpg' alt='Zooey' class='img-fluid'>";
            picModal('zooey', 'sofia');
        } else if (points === 48) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/minimus.jpg' alt='Minimus' class='img-fluid'>";
            picModal('minimus', 'sofia');
        } else if (points === 52) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/robin.jpg' alt='Robin' class='img-fluid'>";
            picModal('robin', 'sofia');
        } else if (points === 56) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/clio.jpg' alt='Clio' class='img-fluid'>";
            picModal('clio', 'sofia');
        } else if (points === 60) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/lulu.jpg' alt='Lulu' class='img-fluid'>";
            picModal('lulu', 'sofia');
        } else if (points === 64) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/leena.jpg' alt='Leena' class='img-fluid'>";
            picModal('leena', 'sofia');
        } else if (points === 68) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/vivian.jpg' alt='Vivian' class='img-fluid'>";
            picModal('vivian', 'sofia');
        } else if (points === 72) {
            sofiaCharactersWon.innerHTML += "<img src='img/sofia/sofia.jpg' alt='Sofia' class='img-fluid'>";
            picModal('sofia', 'sofia');
        } 
    }
    
}

//Starting the game when clicking the START BUTTON
// document.getElementById('words-game-start-button').addEventListener('click',() => {
//     document.getElementById('words-game-start-button').setAttribute('class', 'd-none');
    
//     for(var i =0; i <= 3; i++) {
//         document.getElementsByClassName('words-game-components')[i].classList.remove('d-none');
//         document.getElementsByClassName('words-game-components')[i].classList.add('animated');
//         document.getElementsByClassName('words-game-components')[i].classList.add('fadeIn');

//     }
//     document.getElementsByClassName('words-game-components')[1].classList.add('d-flex');
//     newWord();
// });



//Start Game with a parameter for each individual game
function startGame(gameComponents, subGameCategory) {
    function removeClasses(gameComponentsClass) {
        let len;
        if (gameComponentsClass === 'words-game-components') {
            len = 3;
        } else if (gameComponentsClass === 'countries-game-components' ) {
            len = 2;
        }
        for (var i = 0; i <= len; i++) {
            document.getElementsByClassName(gameComponents)[i].classList.remove('d-none');
            document.getElementsByClassName(gameComponents)[i].classList.add('animated');
            document.getElementsByClassName(gameComponents)[i].classList.add('fadeIn');
        }
    }

    if (gameComponents === 'words-game-components') {
        document.getElementById('words-game-start-button').setAttribute('class', 'd-none');

        removeClasses(gameComponents);

        document.getElementsByClassName('words-game-components')[1].classList.add('d-flex');
        newWord();
    } else if (gameComponents === 'countries-game-components') {
        document.getElementById('countries-start-button').setAttribute('class', 'd-none');

        removeClasses(gameComponents);
        if(subGameCategory === 'countries') {
            startCountriesGame();
            moveEmojis();
            
        } 
    }
}


//Starting the WORDS game when clicking the START BUTTON
document.getElementById('words-game-start-button').addEventListener('click', function(){
    startGame('words-game-components');
} );

//Checking the validity of the player's input 
function checkInput() {
    let playerInputField = document.getElementById('player-submit-text');
    let playerInput = playerInputField.value.toLowerCase();

    document.getElementById('words-points').classList.remove('bounce');
    // let currentWord = document.getElementById('bulg-word').value;

    if (!(playerInput in words)) {
        let tempInput = playerInputField.value;
        playerInputField.style.color = '#587BF1';
        playerInputField.value = 'ОПИТАЙ ОТНОВО!';

        setTimeout(() => {
            playerInputField.value = tempInput;
            playerInputField.style.color = '#5252af';
        }, 700);
        
    } else if(words[playerInput][0] === currentBulgWord ) {
        wordsPoints++;
        document.getElementById('words-points').innerText = wordsPoints.toString();
        playerInputField.style.color = '#1FCB54';
        playerInputField.value = 'БРАВО!';
        
        document.getElementById('words-points').classList.add('bounce');
        setTimeout(() => {
            playerInputField.value = '';
            playerInputField.style.color = '#5252af';
        }, 700);

        checkPoints(wordsPoints, "princess");        
        newWord();
    }
}


//---- COUNTRIES GAME -----

let countries = [
    ['Албания', 'Тирана', 'img/countries/flag/albania.svg', 'img/countries/map/albania.jpg'],
    ['Андора', 'Андора ла Веля', 'img/countries/flag/andorra.svg', 'img/countries/map/andorra.jpg'],
    ['Австрия', 'Виена', 'img/countries/flag/austria.svg', 'img/countries/map/austria.jpg'],
    ['Беларус', 'Минск', 'img/countries/flag/belarus.svg', 'img/countries/map/belarus.jpg'],
    ['Белгия', 'Брюксел', 'img/countries/flag/belgium.svg', 'img/countries/map/belgium.jpg'],
    ['Босна и Херцеговина', 'Сараево', 'img/countries/flag/bosnia.svg', 'img/countries/map/bosnia.jpg'],
    ['България', 'София', 'img/countries/flag/bulgaria.svg', 'img/countries/map/bulgaria.jpg'],
    ['Хърватия', 'Загреб', 'img/countries/flag/croatia.svg', 'img/countries/map/croatia.jpg'],
    ['Кипър', 'Никозия', 'img/countries/flag/cyprus.svg', 'img/countries/map/cyprus.jpg'],
    ['Чехия', 'Прага', 'img/countries/flag/czech-republic.svg', 'img/countries/map/czech-republic.jpg'],
    ['Дания', 'Копенхаген', 'img/countries/flag/denmark.svg', 'img/countries/map/denmark.jpg'],
    ['Естония', 'Талин', 'img/countries/flag/estonia.svg', 'img/countries/map/estonia.jpg'],
    ['Финландия', 'Хелзинки', 'img/countries/flag/finland.svg', 'img/countries/map/finland.jpg'],
    ['Франция', 'Париж', 'img/countries/flag/france.svg', 'img/countries/map/france.jpg'],
    ['Германия', 'Берлин', 'img/countries/flag/germany.svg', 'img/countries/map/germany.jpg'],
    ['Гърция', 'Атина', 'img/countries/flag/greece.svg', 'img/countries/map/greece.jpg'],
    ['Унгария', 'Будапеща', 'img/countries/flag/hungary.svg', 'img/countries/map/hungary.jpg'],
    ['Исландия', 'Рейкявик', 'img/countries/flag/iceland.svg', 'img/countries/map/iceland.jpg'],
    ['Ирландия', 'Дъблин', 'img/countries/flag/ireland.svg', 'img/countries/map/ireland.jpg'],
    ['Италия', 'Рим', 'img/countries/flag/italy.svg', 'img/countries/map/italy.jpg'],
    ['Латвия', 'Рига', 'img/countries/flag/latvia.svg', 'img/countries/map/latvia.jpg'],
    ['Литва', 'Вилнюс', 'img/countries/flag/liechtenstein.svg', 'img/countries/map/lithuania.jpg'],
    ['Люксембург', 'Люксембург', 'img/countries/flag/luxembourg.svg', 'img/countries/map/luxembourg.jpg'],
    ['Македония', 'Скопие', 'img/countries/flag/macedonia.svg', 'img/countries/map/macedonia.jpg'],
    ['Малта', 'Валета', 'img/countries/flag/malta.svg', 'img/countries/map/malta.jpg'],
    ['Молдова', 'Кишинев', 'img/countries/flag/moldova.svg', 'img/countries/map/moldova.jpg'],
    ['Монако', 'Монако', 'img/countries/flag/monaco.svg', 'img/countries/map/monaco.jpg'],
    ['Черна гора', 'Подгорица', 'img/countries/flag/montenegro.svg', 'img/countries/map/montenegro.jpg'],
    ['Холандия', 'Амстердам', 'img/countries/flag/netherlands.svg', 'img/countries/map/netherlands.jpg'],
    ['Норвегия', 'Осло', 'img/countries/flag/norway.svg', 'img/countries/map/norway.jpg'],
    ['Полша', 'Варшава', 'img/countries/flag/poland.svg', 'img/countries/map/poland.jpg'],
    ['Португалия', 'Лисабон', 'img/countries/flag/portugal.svg', 'img/countries/map/portugal.jpg'],
    ['Румъния', 'Букурещ', 'img/countries/flag/romania.svg', 'img/countries/map/romania.jpg'],
    ['Русия', 'Москва', 'img/countries/flag/russia.svg', 'img/countries/map/russia.jpg'],
    ['Сан Марино', 'Сан Марино', 'img/countries/flag/san-marino.svg', 'img/countries/map/san-marino.jpg'],
    ['Сърбия', 'Белград', 'img/countries/flag/serbia.svg', 'img/countries/map/serbia.jpg'],
    ['Словакия', 'Братислава', 'img/countries/flag/slovakia.svg', 'img/countries/map/slovakia.jpg'],
    ['Словения', 'Любляна', 'img/countries/flag/slovenia.png', 'img/countries/map/slovenia.jpg'],
    ['Испания', 'Мадрид', 'img/countries/flag/spain.svg', 'img/countries/map/spain.jpg'],
    ['Швеция', 'Стокхолм', 'img/countries/flag/sweden.svg', 'img/countries/map/sweden.jpg'],
    ['Швейцария', 'Берн', 'img/countries/flag/switzerland.svg', 'img/countries/map/switzerland.jpg'],
    ['Украйна', 'Киев', 'img/countries/flag/ukraine.svg', 'img/countries/map/ukraine.jpg'],
    ['Великобритания', 'Лондон', 'img/countries/flag/united-kingdom.svg', 'img/countries/map/united-kingdom.jpg'],
    ['Ватикан', 'Ватикан', 'img/countries/flag/vatican.svg', 'img/countries/map/vatican.jpg']
];

//Starting the countries game on click
document.getElementById('countries-start-button').addEventListener('click', function () {
    startGame('countries-game-components', 'countries');
});




let realAnswer;
let possibleAnswers = document.getElementsByClassName('possible-answers');
// let answerPosition;
let countriesPoints = 0;

//Displaying the current (0) for the countries game points
document.getElementById('countries-points').innerText = countriesPoints.toString();

function moveEmojis() {
    let emojisPoster = document.getElementById('emojis-poster');

    emojisPoster.style.top = '-45px';
    emojisPoster.style.left = '-50px';
    emojisPoster.style.width = '200px';
    emojisPoster.style.zIndex = '5';
    emojisPoster.style.transform = 'rotate(-15deg)';
    // document.getElementById('countries-game-components-row').style.backgroundColor = "white";
}

function startCountriesGame() { 
    
    //Getting a random number and converting it from a string to a number
    function getRandomArbitrary(min, max) {
        return Number((Math.random() * (max - min) + min).toFixed(0));
    }

    //Generating a random index for the countries array or a random position for the .possible-answers class buttons
    function getNum(realAnswer, starting, ending) {
        let temp = getRandomArbitrary(starting, ending);
        while (temp == realAnswer) {
            temp = getRandomArbitrary(starting, ending);
        }
        return temp;
    }

    //generating the correct answer's index
    let realAnswerRandNum = getRandomArbitrary(0, 43);
    //generating the first fake answer's index
    let randNum1 = getNum(realAnswerRandNum, 0, 43);

    //function to generate the second fake answer's index
    function getRandNum2() {
        let temp = getNum(realAnswerRandNum, 0, 43);
        while (temp === realAnswerRandNum && temp === randNum1) {
            temp = getNum(realAnswerRandNum, 0, 43);
        }
        return temp;
    }
    //call to the function to generate the second fake answer's index
    let randNum2 = getRandNum2();

    //displaying the map
    document.getElementById('map-image').setAttribute('src', countries[realAnswerRandNum][3]);
    
    //displaying the flag
    document.getElementById('flag-image').setAttribute('src', countries[realAnswerRandNum][2]);

    //generating the real answer's position
    let answerPosition = getRandomArbitrary(0,2);
    //generating the first fake answer's position
    let fakeAnswer1Position = getNum(answerPosition,0,2);

    //declaring the second fake answer's position
    let fakeAnswer2Position;
        if (answerPosition == 1 && fakeAnswer1Position == 2) {
            fakeAnswer2Position = 0;
        } else if (answerPosition == 0 && fakeAnswer1Position == 2) {
            fakeAnswer2Position = 1;
        } else if (answerPosition == 0 && fakeAnswer1Position == 1) {
            fakeAnswer2Position = 2;
        } else if (answerPosition == 1 && fakeAnswer1Position == 0) {
            fakeAnswer2Position = 2;
        } else if (answerPosition == 2 && fakeAnswer1Position == 1) {
            fakeAnswer2Position = 0;
        } else if (answerPosition == 2 && fakeAnswer1Position == 0) {
            fakeAnswer2Position = 1;
        }

    realAnswer = countries[realAnswerRandNum][0];
    //positioning the generated values at the positions that were generated
    possibleAnswers[answerPosition].innerText = realAnswer;
    possibleAnswers[fakeAnswer1Position].innerText = countries[randNum1][0];
    possibleAnswers[fakeAnswer2Position].innerText = countries[randNum2][0];    
    
    
    document.getElementById('countries-points').classList.add('bounce');
    waitForAnswer(answerPosition, fakeAnswer1Position, fakeAnswer2Position);
}

function waitForAnswer(answerPos, fakeAns1Pos, fakeAns2Pos) {
    let wrongAnswerCounter = 0;

    function allBlue() {
        let firstValue = possibleAnswers[0].innerText;
        let secondValue = possibleAnswers[1].innerText;
        let thirdValue = possibleAnswers[2].innerText;
        if (wrongAnswerCounter == 1) {
            for (let i = 0; i <= 2; i++) {
                possibleAnswers[i].style.backgroundColor = "blue";
                possibleAnswers[i].innerText = "НОВА ДЪРЖАВА!";
            }
            setTimeout(() => {
                for (let i = 0; i <= 2; i++) {
                    possibleAnswers[i].style.backgroundColor = "#30cfc0";
                }
                setTimeout(() => {
                    document.getElementById('countries-points').classList.remove('bounce');
                }, 1400);
                startCountriesGame();
                possibleAnswers[fakeAns1Pos].removeEventListener('click', allBlue);
                possibleAnswers[fakeAns2Pos].removeEventListener('click', allBlue);
                possibleAnswers[answerPos].removeEventListener('click', clickedButton);
                wrongAnswerCounter = 0;
            }, 1000);
      
        } else {
            for (let i = 0; i <= 2; i++) {
                possibleAnswers[i].style.backgroundColor = "#e2b10f";
                possibleAnswers[i].innerText = "ОПИТАЙ ОТНОВО!";
            }
            setTimeout(() => {
                for (let i = 0; i <= 2; i++) {
                    possibleAnswers[i].style.backgroundColor = "#30cfc0";
                    possibleAnswers[0].innerText = firstValue;
                    possibleAnswers[1].innerText = secondValue;
                    possibleAnswers[2].innerText = thirdValue;
                }
            }, 400);
            setTimeout(() => {
                document.getElementById('countries-points').classList.remove('bounce');
            }, 1400);
            wrongAnswerCounter++;
        }        
    }
    
    possibleAnswers[fakeAns1Pos].addEventListener('click', allBlue);
    possibleAnswers[fakeAns2Pos].addEventListener('click', allBlue);

    possibleAnswers[answerPos].addEventListener('click', clickedButton);

    function clickedButton() {
        countriesPoints++;
        document.getElementById('countries-points').style.color = "#5EBA5E";
        document.getElementById('countries-points').innerText = countriesPoints.toString();

        for (let i = 0; i <= 2; i++) {
            possibleAnswers[i].style.backgroundColor = "#5EBA5E";
        }
        setTimeout(() => {
            for (let i = 0; i <= 2; i++) {
                possibleAnswers[i].style.backgroundColor = "#30cfc0";
            }
        }, 700);

        possibleAnswers[answerPos].removeEventListener('click', clickedButton);
        possibleAnswers[fakeAns1Pos].removeEventListener('click', allBlue);
        possibleAnswers[fakeAns2Pos].removeEventListener('click', allBlue);

        setTimeout(() => {
            document.getElementById('countries-points').classList.remove('bounce');
            document.getElementById('countries-points').style.color = "white";
        }, 1400);
        wrongAnswerCounter = 0;
        checkPoints(countriesPoints, "emoji");
        startCountriesGame();
        
    }
}



// --- LEARN WORDS ---

let displayAllWords = function(){
    let output = '';
    for (let key in words) {
        output = '<li><a href="#" class="word-click" onclick="dispWordComponents(' + "'" + words[key][0] + "'," + "'" + key + "'," + "'" + words[key][1] + "'" + ')">' + '<img src="' + words[key][1] + '" class="word-click-images img-fluid">' + words[key][0] +'</a></li>';
        document.getElementById('display-all-words').innerHTML += output;
    }
};

displayAllWords();

let dispWordComponents = function (bulg, eng, pic) {

    $("#learn-words-components-row > div").fadeOut("fast");

    setTimeout(() => {
        let bulgWord = document.getElementById('learn-words-bulg-word');
        bulgWord.innerText = bulg;

        let engWord = document.getElementById('learn-words-eng-word');
        engWord.innerText = eng;

        let wordPic = document.getElementById('learn-words-pic');
        wordPic.setAttribute("src", pic);
        wordPic.setAttribute("alt", eng);

        $("#learn-words-components-row > div").fadeIn("fast");
    }, 400);
};



// --- LEARN COUNTRIES ---

let displayAllCountries = function () {
    let output = '';

    for(let i = 0; i <= countries.length-1; i++) {
        output += '<li><a href="#" class="country-click" onclick="dispCountryComponents(' + "'" + countries[i][0] + "'," + "'" + countries[i][1] + "'," + "'" + countries[i][2] + "'," + "'" + countries[i][3] + "'"+ ')">' + '<img src="' + countries[i][2] + '" class="country-click-images img-fluid">' + countries[i][0] + '</a></li>';


    }
    document.getElementById('display-all-countries').innerHTML += output;
};

displayAllCountries();



let dispCountryComponents = function (country, capital, flag, map) {

    // $("#learn-countries-components-row > div").fadeOut("fast");

    setTimeout(() => {
        let countryName = document.getElementById('learn-countries-country');
        countryName.innerText = country;

        let capitalName = document.getElementById('learn-countries-capital');
        capitalName.innerText = capital;

        let flagPic = document.getElementById('learn-countries-pic');
        flagPic.setAttribute("src", flag);
        flagPic.setAttribute("alt", countryName);
        flagPic.style.border = "1px solid black";

        let countriesComponentsRow = document.getElementById("learn-countries-components-row");
        countriesComponentsRow.style.backgroundImage = "url(" + map + ")";
        
        // $("#learn-countries-components-row > div").fadeIn("fast");
    }, 200);
};


// -- HEBREW WRITING GAME--

let hebrewPhrases = [
    ["Жаден съм","אני צמא", "ани цаме"],
    ["Подай ми чинията","תביא לי את הצלחת", "тави ли ет хацалахат"],
    ["Искам да играя цял ден","אני רוצה לשחק כל היום", "ани роце лесахек кол хайом"],
    ["Мога да пиша на иврит","אני יכול לכתוב בעברית", "ани яхол лихтов беиврит"],
    ["Вече не съм гладен","אני כבר לא רעב", "ани квар ло раев"],
    ["Хайде да излезем навън","בוא נצא החוצה", "бо нице ахуца"],
    ["Имам много приятели","יש לי הרבה חברים", "йеш ли арбе хаверим"],
    ["Ходи ми се на почивка","בא לי ללכת לחופשה", "ба ли лалехет лехуфша"],
    ["Гладен съм","אני רעב", "ани раев"],
    ["Искам да ям фалафел","אני רוצה לאכול פלאפל", "ани роце леехол фалафел"],
    ["Имам само пет шекела в джоба","יש לי רק חמישה שקלים בכיס", "йеш ли рак хамиша шкалим ба кис"],
    ["Днес бях в Йерусалим","היום הייתי בירושלים", "хайом аити бейерушалайим"],
    ["Трябва ми нова четка за зъби","אני צריך מברשת שיניים חדשה", "ани царих миврешет шинайим хадаша"],
    ["Отивам да си измия зъбите","אני הולך לצחצח שיניים", "ани олех лецахцеах шинайим"],
    ["Хайде да играем футбол","בוא נשחק כדורגל", "бо несахек кадурегел"],
    ["Обичам да слушам музика","אני אוהב לשמוע מוזיקה", "ани оев лишмоа музика"],
    ["Тази е любимата ми песен","זה השיר האהוב עליי", "зе хашир хааув алай"],
    ["Хайде да влезнем в морето","בוא נכנס לים", "бо никанес лаям"],
    ["Планината е много красива","ההר יפה מאוד", "хаар яфе меод"],
    ["Вкъщи си имаме куче","יש לנו כלב בבית", "йеш лану келев бабайт"],
    ["Виж колко високо скачам","תיראה כמה גבוה אני קופץ", "тире кама гавоа ани кофец"],
    ["Мога да говоря иврит","אני יכול לדבר עברית", "ани яхол ледабер иврит"],
    ["Много обичам да ям сладолед","אני מאוד אוהב לאכול גלידה", "ани меод оев леехол глида"],
    ["В Израел лятото е много топло","בקיץ חם מאוד בארץ", "бакаиц меод хам баарец"],
    ["Днес имам рожден ден","היום יש לי יום הולדת", "хайом йеш ли йом уледет"],
    ["Днес се научих да плувам","היום למדתי לשחות", "хайом ламадети лисхот"],
    ["Обичам да посещавам нови места","אני אוהב לבקר במקומות חדשים", "ани оев левакер бемекомот хадашим"],
    ["Искам да си лягам да спя","אני רוצה ללכת לישון", "ани роце лалехет лишон"],
    ["Ще си купя нещо за ядене","אני אקנה לעצמי משהו לאכול", "ани екне леацми машеу леехол"],
    ["Ти говориш английски","אתה מדבר אנגלית", "ата медабер англит"],
    ["Моля те загаси лампата","תכבה את האור בבקשה", "техабе ет хаор бевакаша"],
    ["Тази котка е много гладна","החתול הזה רעב מאוד", "ахатул азе раев меод"],
    ["Имам две сестри","יש לי שתי אחיות", "йеш ли штей ахайот"],
    ["Днес танцувах и много се уморих","היום רקדתי ומאוד התעייפתי", "хайом ракадети вемеод итаяфти"],
    ["Ще ти звънна по телефона","אתקשר אליך בטלפון", "еткашер елейха бателефон"],
    ["Филмът беше много интересен","הסרט היה מאוד מעניין", "асерет ая меод меаниен"],
    ["Той говори много бързо","הוא מדבר מהר מאוד", "ху медабер маер меод"],
    ["Днес ни дадоха много домашни","היום נתנו לנו הרבה שיעורי בית", "хайом натну лану арбе шиурей байт"],
    ["Утре ще учим история","מחר נלמד היסטוריה", "махар нилмад история"],
    ["Този самолет е бял на цвят","המטוס הזה בצבע לבן", "хаматос азе бецева лаван"],
    ["Съседът ми даде чаша захар","השכן הביא לי כוס סוכר", "хашен еви ли кос сукар"],
    ["Часът е девет сутринта","השעה היא תשע בבוקר", "хашаа хи теша бабокер"],
    ["Аз пиша в тетрадката ми","אני רושם במחברת שלי", "ани рошем бамахберет шели"],
    ["Телефонът ми е изключен","הטלפון שלי מכובה", "хателефон шели мехубе"],
    ["Ще гледам филм","אני הולך לראות סרט", "ани олех лирот серет"]
    ["Ще играя на компютъра", "אשחק במחשב", "есахек бамахшев"],
    ["Помогни ми да отворя кутията", "עזור לי לפתוח את הקופסה", "азор ли лифтоах ет хакуфса"],
    ["Навън е много студено", "קר מאוד בחוץ", "кар меод бахуц"],
    ["Много съм щастлив", "אני מאוד שמח", "ани меод самеах"],
    ["Днес ще уча химия", "היום אלמד כימיה", "хайом елмад химия"],
    ["Искам да си лежа в леглото цял ден", "בא לי לשכב במיטה כל היום", "ба ли лишкав бамита кол хайом"],
    ["Супата е много вкусна", "המרק טעים מאוד", "хамарак таим меод"],
    ["Обичам да играя на компютъра", "אני אוהב לשחק במחשב", "ани оев лесахек бамахшев"],
    ["Имам домашно по математика", "יש לי שיעורי בית במטמתיקה", "йеш ли шиурей байт бематематика"],
    ["Днес цялото семейство ще ходим на басейн", "היום כל המשפחה הולכים לבריכה", "хайом кол хамишпаха олхим лaбреха"],
    ["Хайде да играем на криеница", "בוא נשחק מחבואים", "бо несахек махбоим"],
    ["Пие ми се мляко", "בא לי לשתות חלב", "ба ли лиштот халав"],
    ["Харесва ми тази тениска", "אני אוהב את החולצה הזאת", "ани оев ет хахулца хазот"],
    ["Този стол е много удобен", "הכיסא הזה מאוד נוח", "хакисе хазе меод ноах"],
    ["Хайде да излезем да се поразходим", "בוא נצא לטייל קצת", "бо нице летайел кцат"],
    ["Този самолет е много голям", "המטוס הזה גדול מאוד", "хаматос хазе гадол меод"],
    ["Много обичам да спортувам", "אני מאוד אוהב לעשות ספורט", "ани меод оев лаасот спорт"],
    ["Днес учихме много интересни неща", "היום למדנו דברים מאוד מעניינים", "хайом ламадну дварим меод меаниеним"],
    ["Тази игра е много интересна", "המשחק הזה מאוד מעניין", "хамисхак хазе меод меаниен"],
    ["Яде ми се нещо сладко", "בא לי לאכול משהו מתוק", "ба ли леехол машеу маток"],
    ["В междучасието ще играем футбол", "בהפסקה הולכים לשחק כדורגל", "бафсака олхим лесахек кадурегел"],
    ["Научих се да свиря на китара", "למדתי לנגן בגיטרה", "ламадети ленаген бегитара"]
];


let hebrewPoints = 0;

document.getElementById("hebrew-points").innerText = hebrewPoints;

function getRandomArbitrary(min, max) {
    return Number((Math.random() * (max - min) + min).toFixed(0));
}

let randomNumbers = [];

function generateHebrewWord() {
    
    let randNum = getRandomArbitrary(0, 65);

    if( randomNumbers.indexOf(randNum) == -1 ) {
        var hebrewTranslation = document.getElementById("hebrew-phrase-translation").innerText = hebrewPhrases[randNum][0];
        var hebrewPhrase = document.getElementById("hebrew-phrase").innerText = hebrewPhrases[randNum][1];
        var hebrewTranscription = document.getElementById("hebrew-phrase-transcription").innerText = hebrewPhrases[randNum][2];
        if (randomNumbers.length == 30) {
            randomNumbers = [];
        }
        randomNumbers.push(randNum);
        console.log(randomNumbers);
    } else {
        generateHebrewWord();
    }
}

document.getElementById("hebrew-start-button").addEventListener("click",function(){

    document.getElementById("hebrew-start-button").setAttribute("class", "d-none");

    for(let i = 1; i <= 3; i++) {
        document.getElementsByClassName("hebrew-game-components")[i].classList.remove("d-none");
        document.getElementsByClassName("hebrew-game-components")[i].classList.add("animated");
        document.getElementsByClassName("hebrew-game-components")[i].classList.add("fadeIn");
    }

    generateHebrewWord();


});

let hebrewInput = '';

// Add an event listener to every letter from the keyboard
for(let i = 0; i <= 31; i++) {
    document.getElementsByClassName("letter-key")[i].addEventListener("click", function () {
        hebrewInput += hebrewLetters[this.getAttribute("alt")];
        document.getElementById("hebrew-player-input").value = hebrewInput;
    })
}

document.getElementById("hebrew-player-input").addEventListener("keypress", function (event) {
    hebrewInput = document.getElementById("hebrew-player-input").value;

    var x = event.which || event.keyCode;
    if (!(x >= 1488 && x <= 1514) && !(x >= 32 && x <= 64) && !(x >= 91 && x <= 96) && !(x >= 123 && x <= 126) && !(x == 13)){
        deleteLetter();

    } 
});
    

let deleteLetter = function(){
    hebrewInput = document.getElementById("hebrew-player-input").value;
    let splitHebrewInput = hebrewInput.split("");
    splitHebrewInput.pop();
    let joinHebrewInput = splitHebrewInput.join("");

    hebrewInput = joinHebrewInput;

    document.getElementById("hebrew-player-input").value = hebrewInput;
};


document.getElementById("backspace-key").addEventListener("click", function() {
    deleteLetter();
})


function checkHebrewInput(){
    let playerInput = document.getElementById("hebrew-player-input").value;
    if (playerInput == document.getElementById("hebrew-phrase").innerText) {
        hebrewPoints++;
        document.getElementById("hebrew-points").innerText = hebrewPoints;
        document.getElementById("hebrew-points").classList.add("bounce");

        document.getElementById("hebrew-player-input").value = "!БРАВО";
        document.getElementById("hebrew-player-input").style.color = "#1FCB54";

        setTimeout(() => {
            document.getElementById("hebrew-player-input").value = "";
            hebrewInput = '';
            document.getElementById("hebrew-player-input").style.color = "#000000";
            document.getElementById("hebrew-points").classList.remove("bounce");
        }, 1000);


        checkPoints(hebrewPoints, "sofia")
        generateHebrewWord();
    } else {
        document.getElementById("hebrew-player-input").value = "!ОПИТАЙ ОТНОВО";
        document.getElementById("hebrew-player-input").style.color = '#587BF1';

        setTimeout(() => {
            document.getElementById("hebrew-player-input").value = playerInput;
            document.getElementById("hebrew-player-input").style.color = "#000000";
        }, 1000);

    }
}

document.getElementById("enter-key").addEventListener("click", checkHebrewInput);

document.getElementById("hebrew-phrase-submit").addEventListener("click", checkHebrewInput);

//-- LEARN THE KEYBOARD --

const hebrewKeyboard = {
    "q": ["q","/"],
    "w": ["w","'"],
    "e": ["e","ק"],
    "r": ["r","ר"],
    "t": ["t","א"],
    "y": ["y","ט"],
    "u": ["u","ו"],
    "i": ["i","ן"],
    "o": ["o","ם"],
    "p": ["p","פ"],
    "a": ["a","ש"],
    "s": ["s","ד"],
    "d": ["d","ג"],
    "f": ["f","כ"],
    "g": ["g","ע"],
    "h": ["h","י"],
    "j": ["j","ח"],
    "k": ["k","ל"],
    "l": ["l","ך"],
    "colon": [";","ף"],
    "apostrophe": ["'",","],
    "z": ["z","ז"],
    "x": ["x","ס"],
    "c": ["c","ב"],
    "v": ["v","ה"],
    "b": ["b","נ"],
    "n": ["n","מ"],
    "m": ["m","צ"],
    "left-arrow": [",","ת"],
    "right-arrow": [".","ץ"],
    "question-mark": ["/","."]
};


let hebrewLettersList = '';

for (let key in hebrewKeyboard) {
    hebrewLettersList += "<li><img src='img/keyboard/" + key + ".png'>" + "</li>";
}

document.getElementById("display-all-letters").innerHTML = hebrewLettersList;

function getRandomKey(min, max) {
    return Number((Math.random() * (max - min) + min).toFixed(0));
}

let hebrewKeyboardArray = Object.keys(hebrewKeyboard);
let currentHebrewLetter = "";

function generateNewHebrewLetter(){
    document.getElementById("hebrew-letter-input").style.opacity = 1;
    document.getElementById("hebrew-key-submit").classList.add("d-none");

    let tempRand = getRandomKey(0, 30);
    document.getElementById("hebrew-key-pic").setAttribute("src", "img/keyboard/" + hebrewKeyboardArray[tempRand] + ".png");
    currentHebrewLetter = hebrewKeyboardArray[tempRand];
    console.log(currentHebrewLetter);
};

document.getElementById("hebrew-key-submit").addEventListener("click", generateNewHebrewLetter);


document.getElementById("hebrew-letter-input").addEventListener("keypress", function(){
    console.log(hebrewKeyboard[currentHebrewLetter][0]);
    setTimeout(() => {
        if (document.getElementById("hebrew-letter-input").value == hebrewKeyboard[currentHebrewLetter][0] || document.getElementById("hebrew-letter-input").value == hebrewKeyboard[currentHebrewLetter][1]) {

            document.getElementById("hebrew-letter-input").value = '';
            document.getElementById("hebrew-key-pic").style.border = "5px solid #1FCB54";
            document.getElementById("hebrew-letter-game-greeting").style.color = "#1FCB54";
            document.getElementById("hebrew-letter-game-greeting").innerText = "БРАВО!";
            setTimeout(() => {
                document.getElementById("hebrew-key-pic").style.border = "none";
                document.getElementById("hebrew-letter-game-greeting").style.color = "white";
                document.getElementById("hebrew-letter-game-greeting").innerText = "-";
            }, 500);
            generateNewHebrewLetter();
        } else {
            document.getElementById("hebrew-letter-input").value = '';
            document.getElementById("hebrew-key-pic").style.border = "5px solid #587BF1";

            document.getElementById("hebrew-letter-game-greeting").style.color = "#587BF1";
            document.getElementById("hebrew-letter-game-greeting").innerText = "ОПИТАЙ ОТНОВО!";

            setTimeout(() => {
                document.getElementById("hebrew-key-pic").style.border = "none";
                document.getElementById("hebrew-letter-game-greeting").style.color = "white";
                document.getElementById("hebrew-letter-game-greeting").innerText = "-";
            }, 500);

        }  
    }, 200);
})




// function myKeyPress(e) {
//     var keynum;

//     if (window.event) { // IE                    
//         keynum = e.keyCode;
//     } else if (e.which) { // Netscape/Firefox/Opera                   
//         keynum = e.which;
//     }

//     alert(String.fromCharCode(keynum));
// }
